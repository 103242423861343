.formWidth{
    width: 100%;
}
.deleteButton {
    position: absolute !important;
    right: 30px;
    margin-top: 20px !important;
}
.file-input{
    display: flex;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-width: 0%;
    border-color: rgba(0, 0, 0, 0.23);
    padding: 16.5px 14px;
    cursor: pointer;
    &:focus {
        border-color: #006539;
        border-width: 2px;
    }
    &>div{
        height: 1.4375em;
        max-width: 100%;
        display: flex;
        align-items: center;
    }
}