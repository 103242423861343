.button-rounded {
    border-radius: 99px;
}


.button-padding {
    padding: 8px 12px;
}

.button-margin {
    margin: 4px 12px;
}

.button-color {
    color: getColor(white);
}