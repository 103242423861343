.details-position {
    display: flex;
    justify-content: space-between;
    margin: 8px;
}
.linestyle {
    border-width: 0.5px;
    border-color: black;
    margin: 0px 8px 0px 8px
}
